<template>
  <div>
    <CommonStandardList
        slug="Employee"
        :module-name="STORE_MODULE_NAME"
        :table-headers="tableHeaders"
        :form-setting="form"
        :api-urls="apiUrls"
    />
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {onUnmounted, ref} from "@vue/composition-api"

// sidebar
import CommonStandardList from "@/views/common/list/standard-list/CommonStandardList"
import store from "@/store"
import companyStoreModule from "@/views/company-management/companies/companyStoreModule"
import useCompaniesList from "@/views/company-management/companies/company-list/useCompaniesList"
import useCompanyConfigList from "@/views/company-management/companies/company-config/useCompanyConfigList"

export default {
  components: {
    CommonStandardList
  },
  mounted() {
    this.fetchCompanies()
  },
  setup() {
    const STORE_MODULE_NAME = "em-employee"

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, companyStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      allCompanyNames,
      fetchCompanies
    } = useCompanyConfigList({moduleName: STORE_MODULE_NAME})

    const apiUrls = ref(
        {
          list: "/admin/employee",
          add: "/admin/employee"
        }
    )

    const tableHeaders = ref([
      {text: "Company Name", value: "company_name", filter: true},
      {text: "Employee Name", value: "name", filter: true},
      {text: "Employee ID", value: "employee_id", filter: true},
      {text: "Designation", value: "designation"},
      {text: "Salary", value: "salary"},
      {text: "Phone", value: "phone"},
      {text: "Email", value: "email"},
      {text: "Total Applications", value: "total_applications"},
      {text: "Monthly Total Attendance", value: "total_attendance_this_month"},
      {text: "Created At", value: "created_at", sortable: false},
      {
        text: "ACTIONS",
        value: "actions",
        align: "center",
        sortable: false,
      },
    ])

    const form = ref([
      {name: "company_name", type: "select", required: true, options: allCompanyNames},
      {name: "name", type: "text", required: true},
      {name: "employee_id", type: "text", required: true},
      {name: "designation", type: "text", required: true},
      {name: "salary", type: "text", required: true},
      {name: "phone", type: "text", required: true},
      {name: "email", type: "text", required: true}
    ])

    const searches = []

    return {
      STORE_MODULE_NAME,
      tableHeaders,
      form,
      apiUrls,
      fetchCompanies,
      // icons
      icons: {},
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
